import { useAuth } from '@/use';
import { odata } from '../conf';

const Actions = class {
  constructor(name, collection) {
    this[`LOAD_${collection}`] = async ({ commit, rootGetters }) => {
      const token = await useAuth.getAccessToken();
      const data = await odata.o(rootGetters, token)
        .get(collection)
        .query({ $filter: 'IsDeleted eq false' });
      commit(`SET_${collection}`, data);

      return data;
    };
    this[`LOAD_${name}`] = async ({ commit, rootGetters }, id) => {
      const token = await useAuth.getAccessToken();
      const i = odata.parseId(id);
      const data = await odata.o(rootGetters, token)
        .get(`${collection}(${i})`)
        .query();
      commit(`SET_${name}`, data);

      return data;
    };
    this[`SAVE_${name}`] = async ({ commit, state, rootGetters }, data) => {
      const token = await useAuth.getAccessToken();
      const result = await odata.o(rootGetters, token)
        .post(collection, data)
        .query();
      const items = state[collection];
      items.push(result);
      commit(`SET_${collection}`, items);
    };
    this[`CREATE_${name}`] = async ({ commit, state, rootGetters }, data) => {
      const token = await useAuth.getAccessToken();
      const result = await odata.o(rootGetters, token)
        .post(collection, data)
        .query();
      const items = state[collection];
      items.push(result);
      commit(`SET_${collection}`, items);

      return result;
    };
    this[`UPDATE_${name}`] = async ({ commit, state, rootGetters }, data) => {
      const token = await useAuth.getAccessToken();
      const i = odata.parseId(data.id);
      await odata.o(rootGetters, token)
        .patch(`${collection}(${i})`, data)
        .query();

      const items = state[collection];
      if (items?.length > 0) {
        const updatedItem = items.find((l) => l.id === i);
        Object.assign(updatedItem, data);

        commit(`SET_${collection}`, items);
      }

      return data;
    };
    this[`DELETE_${name}`] = async ({ commit, state, rootGetters }, id) => {
      const token = await useAuth.getAccessToken();
      const i = odata.parseId(id);
      await odata.o(rootGetters, token)
        .delete(`${collection}(${i})`)
        .query();
      const items = state[collection].filter((item) => item.id !== i);
      commit(`SET_${collection}`, items);
    };
  }
};

export default Actions;
